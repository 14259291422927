.maptopheader {
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    padding: 20px;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.maptopheader::-webkit-scrollbar {
    display: none;
  }

.maptopheader::before {
    content: "";
    width: 20px;
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    z-index: 5;
}

.maptopheader::after {
    content: "";
    width: 20px;
    height: 80px;
    position: fixed;
    right: 0;
    top: 0;
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    z-index: 5;
}

.maptopheadercontainer {
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.stationcontainer {
    display: flex;
    margin-left: 24px;
    padding-right: 12px;
}

.stationcontaineritem {
    width: 56px;
    height: 56px;
    z-index: 2;
    border-radius: 56px;
    background-size: cover;
    margin: 0px 8px;
}

.poidot {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    border: 2px solid #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: transform 0.2s;
}
.poidot:active {
    transform: scale(1.1);
}

.poidot img {
    width: 24px;
    height: 24px;
}

.maptooltip {
    display: inline-block;
    max-width: 200px;
    background-color: #fff;
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 14px;
    text-align: center;
    font-family: var(--font-body);
}

.maptooltip p {
    margin: 0px auto;
    font-size: 16px;
    font-weight: bold;
}


.maptooltip::after {
    content: "";
    background-image: url("img/tooltiparrow.svg");
    width: 20px;
    height: 10px;
    background-size: cover;
    position: absolute;
    bottom: -9px;
    left: calc(50% - 10px);
}

.stationpopupcontainer {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    width: 100vw;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 48px 24px 24px 24px;
    box-sizing: border-box;
    visibility: visible;
    transition: opacity 0.2s, transform 0.2s, visibility 0.2s;
}

.stationpopupcontainerclosed {
    opacity: 0;
    transform: translateY(60px);
    visibility: hidden;
}

.stationpopupcloseicon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 12px;
    opacity: 0.6;
}

.stationpopup {
    width: 100%;
    background-color: #fff;
    padding: 56px 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.stationpopup p {
    margin: 0px auto;
    font-size: 18px;
    font-weight: bold;
}

.stationpopupimage {
    width: 56px;
    height: 56px;
    position: absolute;
    border-radius: 56px;
    background-size: cover;
    position: absolute;
    top: -30px;
    border: 3px solid #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
}

.openstationbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--main-color);
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    color: #fff;
    margin: 0px auto;
    margin-top: 24px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
}


.openstationbtnactive::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slideprimarybtn 3s infinite;
    background: #fff0;
    background: linear-gradient(to right, #fff0 0%, #fff4 50%, #fff0 100%);
}

.openstationbtn img {
    width: 20px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.mapbuttonbadge {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: opacity 0.2s;
}

.mapbuttonbadge img {
    width: 24px;
    height: 24px;
    display: block;
}

.homebutton {
    left: 0;
    bottom: 56px;
    padding: 8px 16px 8px 12px;
    border-radius: 0px 40px 40px 0px;
}

.timebadge {
    right: 0;
    bottom: 56px;
    padding: 8px 12px 8px 12px;
    border-radius: 40px 0px 0px 40px;
}

.timebadge img {
    margin-right: 4px;
}

.stationmarkeractive {
    animation: jump 1s ease-in-out infinite;
    /*animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    animation-duration: 2s;
    animation-iteration-count: infinite;*/
}


@keyframes jump {
    0%, 100% {
      transform: translateY(0) scaleY(0.95);
    }
    50% {
      transform: translateY(-20px) scaleY(1.05);
    }
  }

  @keyframes bounce-7 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-40px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

/* PROGRESS ITEM */

.mapprogresscontainer {
    position: fixed;
    top: 16px;
    left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mapprogressstations {
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    box-shadow: 0px 4px 12px #0002;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    box-sizing: border-box;
    padding: 6px;
}

.mapprogresstext {
    font-size: 14px;
    color: #444;
    line-height: 1;
}

.mapprogresstext span {
    font-size: 28px;
    color: #000;
    font-weight: bold;
    margin-right: 2px;
}

.mapprogresspoints {
    background-color: #fff;
    padding-left: 32px;
    padding-right: 16px;
    height: 48px;
    border-radius: 16px;
    box-shadow: 0px 4px 12px #0002;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: -24px;
    z-index: 1;
}

.mapprogresspoints img {
    width: 22px;
    margin-right: 2px;
}

.positiondotcont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.positiondot {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #0066FF;
    border-radius: 100%;
    border: 4px solid #fff;
    box-shadow: 0px 2px 8px #0004;
}

.positiondotcircle {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #0066FFaa;
    border-radius: 100%;
    animation: positiondotcircle 2s linear infinite;
}

@keyframes positiondotcircle {
    from {transform: scale(0); opacity: 1;}
    to {transform: scale(1); opacity: 0;}
}

.locationbutton {
    position: absolute;
    right: 16px;
    top: -56px;
    width: 48px;
    height: 48px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0px 4px 8px #0002;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.locationbutton img {
    width: 32px;
}

.maplibregl-ctrl-bottom-right  {
    display: none;
}

.notificationwrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    padding-top: 0px;
    box-sizing: border-box;
    transition: all 0.2s;
}

.notificationwrapper-closed {
    opacity: 0;
    visibility: hidden;
}

.notification {
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px #0003;
    border-radius: 16px;
    margin-top: 8px;
    position: relative;
}

.notificationtextcont {
    width: 75%;
}

.notificationheadline {
    font-weight: bold;
    font-size: 16px;
}

.notificationtext {
    font-size: 14px;
    color: #444;
}

.notificationicon {
    width: 32px;
    margin-right: 12px;
}

.notificationcloseicon {
    width: 20px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    opacity: 0.6;
}

.notificationbutton {
    color: #000;
    border: 2px solid #0004;
    display: inline-flex;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 12px;
    cursor: pointer;
    align-items: center;
    margin-top: 8px;
}

/* MODAL STATION PROGRESS */

.modalstationprogress {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 1;
    transition: all 0.2s;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .modalstationprogressclosed {
    opacity: 0;
    visibility: hidden;
  }
  
  .modalstationprogressinner {
    background-color: #fff;
    height: 80%;
    width: 100%;
    max-width: 600px;
    border-radius: 32px 32px 0px 0px;
    box-sizing: border-box;
    transition: transform 0.2s;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .modalstationprogressclosed .modalstationprogressinner {
    transform: translateY(200px);
  }

  .modalstationprogresscard {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 16px 0px;
  }

  .modalstationprogressclose {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    padding: 16px;
  }

  .modalstationprogressinnerlist {
    padding: 24px;
    padding-top: 48px;
    overflow: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  

  .modallocationinner {
    background-color: #fff;
    max-height: 90%;
    width: 100%;
    max-width: 600px;
    border-radius: 32px 32px 0px 0px;
    box-sizing: border-box;
    transition: transform 0.2s;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: scroll;

  }

  .modalstationprogressclosed .modallocationinner {
    transform: translateY(200px);
  }

  .modallocationinnercontent {
    padding: 24px;
    padding-top: 48px;
    overflow: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .modallocationinnercontent ol {
    text-align: left;
    margin-top: 24px;
  }

  .modallocationinnercontent ol li {
    margin-bottom: 8px;
  }

  .modalstationprogressinner .mapprogressstations {
    position: absolute;
    top: -40px;
  }

  .modalstationprogressbackground {
    background-color: #0009;
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .modalstationprogresscard .stationcheckmark {
    width: 32px;
    height: 32px;
    background-color: #5ca24b;
    border-radius: 20px;
    position: absolute;
    top: -4px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    box-shadow: 0px 4px 8px #0004;
  }

  .stationbubble {
    position: absolute;
    top: -40px;
    background-color: #000;
    display: inline-block;
    max-width: 200px;
    background-color: #fff;
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    text-align: center;
    font-family: var(--font-body);
    z-index: 5;
  }

  .stationbubble-active {
    animation: bubbleupdown 1.5s ease-in-out infinite;
  }
  
  @keyframes bubbleupdown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-12px);
    }
  }


  .stationbubble::after {
    content: "";
    background-image: url("img/tooltiparrow.svg");
    width: 20px;
    height: 10px;
    background-size: cover;
    position: absolute;
    bottom: -9px;
    left: calc(50% - 10px);
}

.modalinstallinfoicon {
    width: 80px;
    position: absolute;
    top: -40px;
    box-shadow: 0px 8px 12px #0003;
    border-radius: 16px;
    animation: iconupdown 1.5s ease-in-out infinite alternate;
  }

  @keyframes iconupdown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(10px);
    }
  }

