.languagepickerwrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  justify-content: space-between;
  padding: 24px 0px;
  box-sizing: border-box;
}

.languagelist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 16px;
}

.languagelist {
  font-size: 16px;
}

.languagelist-3 {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4px;
}

.languagelist-3 p {
  font-size: 13px;
}

.languageswitchercard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  border-radius: 12px;
}

.languageswitchercard-active {
  background-color: #0001;
}

.languageswitchercard img {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.languageswitchercard p {
  margin: 0px auto;
  margin-top: 16px;
}

.consenttext {
  text-align: center;
  font-size: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
  color: #666;
}

.consenttext a {
  color: inherit;
  text-decoration: underline;
}