@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');


:root {
  --main-color: #669B12;
  --main-color-light: #e1f4c2;
  --dark-color: #45332f;
  --font-body: 'Work Sans', sans-serif;
  --font-display: 'Work Sans', sans-serif;
  --font-display-weight: bold;
}

body {
  font-family: var(--font-body);
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
}

.wrapper {
  width: 86%;
  max-width: 400px;
  margin: 0px auto;
}

::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3 {
  font-family: var(--font-display);
  font-weight: var(--font-display-weight);
}

h1 {
  font-size: 48px;
  line-height: 100%;
  margin-top: 12px;
  font-weight: normal;
}

h2 {
  font-size: 24px;
  line-height: 110%;
}

a {
  color: inherit;
  text-decoration: none;
}

.button-primary {
  color: #fff;
  background-color: var(--main-color);
  text-align: center;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.1s, opacity 0.3s;
}

.button-primary:active {
  transform: scale(0.95);
}

@keyframes slide {
	0% {transform:translateX(-100%);}
  40% {transform:translateX(100%);}
	100% {transform:translateX(100%);}
}

@keyframes slideprimarybtn {
	0% {transform:translateX(-100%);}
  80% {transform:translateX(100%);}
	100% {transform:translateX(100%);}
}


.button-primary::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slideprimarybtn 3s infinite;
    background: #fff0;
    background: linear-gradient(to right, #fff0 0%, #fff4 50%, #fff0 100%);
}

/* Shine animation 
@keyframes shine {
  0% {
    opacity: 0;
    transform: translate(-150%, -150%) rotate(45deg);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(150%, 150%) rotate(45deg);
  }
}

.button-primary::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(45deg);
  animation: shine 2s linear infinite;
  pointer-events: none; 
}
*/

.button-secondary {
  color: #000b;
  border: 2px solid #0003;
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 18px;
  cursor: pointer;
  align-items: center;
}

.button-secondary-light {
  color: #fff;
  border: 2px solid #fff5;
}

.button-secondary img {
  width: 20px;
  margin-right: 6px;
}

.overviewwrapper {
  position: relative;
}

.trailcard {
  width: 100%;
  height: 400px;
  border-radius: 24px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
}

.trailcard:active {
  /*transform: scale(0.95);*/
}

.trailcardlogo {
  width: 100%;
  z-index: 1;
}

.trailcard p {
  font-size: 13px;
  text-align: center;
  margin-bottom: 4px;
  margin-top: 12px;
  z-index: 1;
}

.trailcard h3 {
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  margin: 0px;
  line-height: 120%;
  z-index: 1;
}

.carddoneicon {
  background-color: #92D469;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.carddoneicon img {
  width: 24px;
}

.badgecounter {
  display: flex;
  align-items: center;
}

.badgesmall {
  width: 48px;
  height: 48px;
  border-radius: 60px;
  margin-right: -16px;
}


.badgesmall-full {
  border: 2px solid #fff;
  box-shadow: 0px 4px 8px #0002;
}

.badgesmall-empty {
  /*border: 2px dashed #cecece;*/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23fff' rx='100' ry='100' stroke='%23cecece' stroke-width='5' stroke-dasharray='2%2c8' stroke-dashoffset='64' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.badgecounterlabel {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.badgecounterlabel img {
  width: 24px;
  height: 24px;
}

.trailcardgradient {
  width: 100%;
  height: 300px;
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  z-index: 0;
}

.mapbutton {
  color: #fff;
  background-color: #000;
  padding: 12px 16px;
  position: fixed;
  z-index: 4;
  bottom: 20px;
  font-weight: bold;
  border-radius: 16px;
  font-size: 18px;
  margin: 0px auto;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s;
}

.badgetopsection {
  width: 100%;
  background: rgb(35, 67, 80);
  background: linear-gradient(0deg, #275163 0%, rgb(20, 32, 42) 100%);
  position: relative;
  z-index: 0;
  transition: height 0.5s;
  color: #fff;
}

.badgetopsectionactive {
  /*max-height: 85vh;*/
  max-height: auto;
}


.badgetopsectionactive .badgecollectionsection {
  opacity: 1;
}

.badgetopsectionwhite {
  width: 100%;
  height: 40px;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  bottom: -2px;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -12px 8px #0002;
}

.badgetopsectionwhite img {
  margin: 0px auto;
  display: block;
  margin-top: 16px;
  width: 48px;
}

.badgetopsectionwhitearrow {
  animation: arrowupdown 0.5s alternate infinite;
}

@keyframes arrowupdown {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(5px);
  }
}

.badge {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  margin: 0px 6px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.badgeshine {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

}

.badgedone {
  box-shadow: 0px 8px 16px #0005, inset 4px 4px 4px #0003;
  /*box-shadow: 0px 0px 24px #fffc, inset 4px 4px 4px #0003;
  animation: badgeglow 3s infinite alternate ease-in-out;*/
  overflow: hidden;
  transform: translateZ(0);
}

.badgedone:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 3s infinite;
  background: #fff0;
  background: linear-gradient(to right, #fff0 0%, #fffc 50%, #fff0 100%);
  /* W3C */
}

.socialshareimagebadge {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  margin: 0px 6px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-shadow: 0px 8px 16px #0005, inset 4px 4px 4px #0003;
}


@keyframes badgeglow {
  from {
    box-shadow: 0px 0px 16px #fff5, inset 4px 4px 4px #0003;
  }

  to {
    box-shadow: 0px 0px 32px 4px #fffa, inset 4px 4px 4px #0003;
  }
}

.badgeinactive {
  filter: grayscale(1);
  opacity: 0.5;
  /*border: 4px dashed;*/
}

.badgetopsectioncontainer {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 20px;
  transition: opacity 0.5s, transform 0.5s;
}

.badgecollectionheadline {
  font-size: 32px;
  width: calc(100% - 80px);
  color: #fff;
}

.badgecollectionwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 32px;
  column-gap: 20px;
  margin: 48px 0px;
}

.badgecollectionitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  min-height: 120px;
}

.badgecollectionitemempty {
  width: 106px;
  height: 106px;
  background-color: #0004;
  border-radius: 200px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  /*animation: badgeemptyrotate 3s ease-in-out infinite alternate;*/
}

.badgecollectionitemempty img {
  width: 96px;
  opacity: 0.4;
}

@keyframes badgeemptyrotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(20deg);
  }
}

.badgecollectionitem:active {
  transform: scale(0.95);
}

.badgecollectionitemtitle {
  margin-top: 16px;
  margin-bottom: 2px;
}

.badgecollectioniteminfotext {
  font-size: 12px;
  opacity: 0.7;
  margin: 0px auto;
}

.badgecollectionwrapper .badge {
  width: 100px;
  height: 100px;
}

.badgecollectionwrapper .badge::after {
  width: 100px;
  height: 100px;
}

.badgetopsectionactive .badgetopsectioncontainer {
  opacity: 0;
  transform: translateY(100px);
}

.badgetopsectionclosed .badgecollectionsection {
  transform: translateY(-100px);
  opacity: 0;
}

.badgecollectionsection {
  z-index: 1;
  transform: translateY(0px);
  overflow: scroll;
  height: 100%;
  width: 100%;
  padding: 40px 24px;
  box-sizing: border-box;
  transition: opacity 0.5s, transform 0.5s;
}

.badgecollectionemptystate {
  background-color: #0003;
  backdrop-filter: blur(8px);
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 48px;
  margin-top: 64px;
}

.badgecollectionemptystatebadge {
  margin-left: -24px;
}

.badgecollectionemptystatebadge .badge {
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
}

.badgecollectionemptystate p {
  margin: 0px auto;
}

.badgetopsectionclosed {
  /*max-height: 140px;*/
}

.badgetopsectionclosed .badgetopsectionwhitearrow img {
  transform: rotate(180deg);
}

.badgeanimation {
  animation: badgeanimation 4s infinite linear;
}

@keyframes badgeanimation {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

.modalmap {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ddddb2;
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: all 0.5s;
  visibility: visible;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  box-shadow: 0px 20px 80px #0003;
}

.modalmapclosed {
  opacity: 1;
  transform: scale(0.8) translateX(-150%) rotate(-20deg);
  border-radius: 32px;
  visibility: hidden;
}

.regionswrapperdev {
  display: flex;
  justify-content: center;
}

.regionswrapperdev div {
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.regionswrapperdevitem {
  background-color: #eee;
  font-weight: bold;
}

.loadingscreen {
  display: flex;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.loadingscreen .loadingicon {
  opacity: 0;
  width: 40px;
  animation: loadingfadein 1s linear 0.5s forwards, loadingicon 1s linear infinite;
}

.loadingsmall {
  width: 24px;
  animation: loadingicon 1s linear infinite;
}

.loadingscreen .emptystateicon {
  max-width: 320px;
  width: 90%;
}

.emptystatescreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes loadingicon {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loadingfadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.overviewlistbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
}

.overviewlistbar p {
  font-size: 18px;
}

.mapbutton-secondary {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 16px;
  border: 2px solid #0003;
  cursor: pointer;
}

.badgecountercont {
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  position: relative;
}

.badgecountercont b {
  font-size: 28px;
}


.badge-detail-home {
  width: 44px;
  height: 44px;
  background-size: cover;
  border-radius: 100%;
  border: 2px solid #fff;
  margin: 0px auto;
  box-shadow: 0px 4px 8px #0004, inset 6px 6px 6px #0003;
  position: relative;
  overflow: hidden;
  margin-left: 8px;
}

.badge-detail-home:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 1;
  animation: slide 3s infinite;

  background: #fff0;
  background: linear-gradient(to right, #fff0 0%, #fffc 50%, #fff0 100%);
  /* W3C */
}


.modalbadge {
  width: 100vw;
  height: calc(100vh + 1px);
  height: calc(100dvh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  color: #fff!important;
  background-color: var(--dark-color);
  z-index: 3;
  opacity: 1;
  transform: scale(1);
  transition: all 0.5s;
  visibility: visible;
  border-radius: 0px;
  /*overflow: scroll;*/
  box-shadow: 0px 20px 80px #0003;
  transition-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
}

.modalbadgeclosed {
  opacity: 1;
  transform: scale(0.8) translateX(150%) rotate(20deg);
  visibility: hidden;
  border-radius: 24px;

}

.clickable {
  transition: transform 0.1s;
  cursor: pointer;
}

.clickable:active {
  transform: scale(0.9);
}

/* REGION OVERVIEW */

.region-overview .regionbox {
  padding: 24px 4px;
  border-bottom: 2px solid #eee;
  font-size: 20px;
}

.loadingfade {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 300;
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.loadingfadeclouds {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}



.cloudloadingrect {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 0.5s forwards;
}

@keyframes cloudzoom {
  0% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: scale(6);
    opacity: 0;
    visibility: hidden;
  }
}

.clouds1 {
  width: 600px;
  animation: cloudzoom 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
}

.clouds2 {
  width: 500px;
  animation: cloudzoom 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
}

.clouds3 {
  width: 400px;
  animation: cloudzoom 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
}

.loadingcloud {
  position: absolute;
}

.cardtagcontainer {
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 200px;
}

.detailtagcontainer {
  margin-top: 16px;
  margin-bottom: 32px;
}

.tag {
  color: #fff;
  background-color: #0008;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.tag-easy {
  border-left: 4px solid #24b0f9;
}

.tag-medium {
  border-left: 4px solid #ff3a3a;
}

.tag-hard {
  border-left: 4px solid #000000;
}

.detailtagcontainer .tag {
  color: #000c;
  background-color: #0001;
}

.detailtagcontainer .tag img {
  display: none;
}

.tag img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

/* MODAL SOCIAL SHARE */

.socialshareimg {
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 12px 20px #0003;
  display: block;
  animation: socialshareimgpopup 0.6s ease;
}

@keyframes socialshareimgpopup {
  0% {transform: scale(0.7); opacity: 0;}
  50% {transform: scale(1.1); opacity: 1;}
  100% {transform: scale(1);}
}

.socialshareimgloading {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #0002;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalsocialshare {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  opacity: 1;
  transition: all 0.2s;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modalsocialshareclosed {
  opacity: 0;
  visibility: hidden;
}

.modalsocialshareinner {
  background-color: #fff;
  height: auto;
  max-height: 95%;
  width: 100%;
  max-width: 480px;
  border-radius: 32px 32px 0px 0px;
  box-sizing: border-box;
  transition: transform 0.3s;
  transition-timing-function: ease-out;
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  overflow: scroll;
}

.modalsocialshareclosed .modalsocialshareinner {
  transform: translateY(200px);
}

.modalsocialshareheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 4px 24px;
  padding-right: 12px;
  box-sizing: border-box;
}

.modalsocialshareheader img {
  width: 24px;
  padding: 16px;
}

.modalsocialsharebackground {
  background-color: #0009;
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  position: absolute;
}

.socialshareimage {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  position: relative;
}

.modalsocialsharebuttons {
  padding: 20px;
  text-align: center;
}

.socialshareimagebottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  padding-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  color: #fff;
  box-sizing: border-box;
}

.socialshareimagetext {
  margin-left: 8px;
}

.socialshareimagetextheadline {
  font-family: var(--font-display);
  font-size: 24px;
  line-height: 1.1;
  margin: 0px auto;
  margin-bottom: 4px;
}


.socialshareimagetextinfo {
  font-family: var(--font-body);
  font-size: 14px;
  margin: 0px auto;
  opacity: 0.7;
}

.modalsocialsharebuttons input[type="file"] {
  display: none;
}

.socialshareimagedisplay {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 12px 20px #0003;
}

.custom-file-upload {
  color: #4F4F4F;
  border: 2px solid #bbb;
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 18px;
  cursor: pointer;
  align-items: center;
}

.custom-file-upload img {
  width: 20px;
  margin-right: 6px;
}

.page-error {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.socialsharehidden {
  position: fixed;
  top: 120%;
}

.socialsharesmalltext {
  font-size: 10px;
  display: inline-block;
  opacity: 0.6;
  text-align: center;
  width: 100%;
}

.socialsharelogo {
  width: 70px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.socialsharelogobig {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.tabcontainer {
  display: flex;
  flex-direction: row;
}

.tabitem {
  width: 100%;
  text-align: center;
  padding: 8px;
  color: #777;
  cursor: pointer;
}

.tabitemactive {
  border-bottom: 2px solid #000;
  color: #000;
  font-weight: bold;
}

.installprompt {
  background-color: var(--dark-color);
  color: #fff;
  padding: 16px;
}

.badgenotification {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 8px;
  z-index: 2;
}

.badgenotificationcirclesmall {
  width: 12px;
  height: 12px;
  background-color: #f35454;
  border-radius: 100%;
  border: 2px solid #fff;
  position: absolute;
}

.badgenotificationcirclebig {
  width: 12px;
  height: 12px;
  background-color: #f35454;
  border-radius: 100%;
  position: absolute;
  animation: badgenotification 1.5s ease-out infinite;
}

@keyframes badgenotification {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.cardwrapper {
  transition: all 0.5s;
}

.cardactive {
  opacity: 0.7;
  transform: scale(0.9);
}

.modalappinstallinner {
  overflow-y: visible!important;
}


