.page-traildetail {
  width: 100vw;
  height: calc(100vh + 1px);
  height: calc(100dvh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  z-index: 10;
  background-color: #fff;
  z-index: 50;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);
  visibility: visible;
}

.traildetailscrollcontainer {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}

.page-traildetailclosed {
  opacity: 0;
  transform: translateY(100vh);
  transform: translateY(100dvh);
  visibility: hidden;
}

.trailer {
  width: 100%;
  height: 320px;
  background-color: #ddd;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.trailer::after {
    content: "";
    width: 100%;
    height: 160px;
    position: absolute;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
    bottom: 0;
    z-index: 0;
}

.trailerbackgroundimage {
  width: 100%;
  height: 320px;
  object-fit: cover;
  object-position: center;
  position: absolute;
  /*top: 0px;*/
  left: 0px;
}

.trailerplayicon {
  background-color: #0008;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  animation: playicongrow 1s infinite alternate ease-in-out;
}

@keyframes playicongrow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.trailerplayicon img {
  width: 40px;
}


.badge-detail {
  width: 88px;
  height: 88px;
  background-size: cover;
  border-radius: 100%;
  border: 3px solid #fff;
  margin: 0px auto;
  box-shadow: 0px 8px 18px #0004, inset 6px 6px 6px #0003;
  margin-top: -47px;
  position: relative;
  overflow: hidden;
}

.badge-map {
  width: 56px;
  height: 56px;
  border: 2px solid #fff;
}



.badge-detail:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:88px;
	height:88px;
	position: absolute;
	z-index:1;
	animation: slide 3s infinite;
	 
  background: #fff0;
	background: linear-gradient(to right, #fff0 0%, #fffc 50%, #fff0 100%); /* W3C */
}

/* animation */



.detail-logo-wrapper {
  max-width: 260px;
  margin: 0px auto;
}

.detail-logo-wrapper h3 {
  color: #000;
  font-weight: normal;
  font-size: 32px;
  text-align: center;
  line-height: 120%;
  margin: 0px auto;
}

.detail-logo {
  width: 100%;
  margin: 0px auto;
  display: block;
}


.closebutton {
  width: 56px;
  height: 56px;
  background-color: #0007;
  border-radius: 16px;
  position: fixed;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(24px);
  z-index: 5;
  cursor: pointer;
}

.closebutton img {
  width: 28px;
  height: 28px;
}


.detaildescription {
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 48px;
}

.stationlistwrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 32px;
}

.stationlistwrapper::after {
  content: "";
  height: calc(100% - 20px);
  width: 3px;
  background-color: #ccc;
  position: absolute;
  top: 5px;
  left: 33px;
  z-index: 0;
}

.stationlistitem {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  z-index: 2;
}

.stationlistitemimage {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 3px solid #fff;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.stationlistitemimageinside {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.stationlistitemtext {
  margin-left: 16px;
  margin-top: 22px;
}

.stationlistitemtext p {
  margin: 0px;
}

.startbuttonbottombox {
  background-color: #fff;
  padding: 16px 0px;
  position: fixed;
  width: 100%;
  z-index: 20;
  bottom: 0;
  left: 0;
  border-top: 2px solid #eee;
}

/*
.trailinfocontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
*/

.trailinfocontainer {
  display: flex;
  justify-content: space-between;
}

.trailinfocontainer p {
  font-weight: normal;
  font-size: 16px;
}

.trailinfocontainer p span {
  font-weight: bold;
  font-size: 32px;
}

.trailinfobox {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

.trailinfobox img {
  width: 40px;
  margin-bottom: 6px;
}

.stationmarker {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
  transition: all 0.2s;
}

.stationmarker .stationcheckmark {
  width: 32px;
  height: 32px;
  background-color: #5ca24b;
  border-radius: 20px;
  position: absolute;
  top: 4px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  box-shadow: 0px 4px 8px #0004;
}

.markerbg {
  width: 72px;
  z-index: 1;
  opacity: 1;
}

.markerimginside {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 100%;
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.markerimginsideinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}


.trailtitlebox  h2 {
  font-size: 24px;
  margin: 0px auto;
}

.trailtitlebox p {
  font-size: 14px;
  margin: 0px auto;
  margin-top: 4px;
}

.detail-section-anfahrt-textcont p {
  margin: 0px auto;
  line-height: 160%;
}

.traildetailbadgebox {
  background-color: var(--dark-color);
  color: #fff;
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.traildetailbadgeboxtext h2 {
  margin: 0px auto;
  margin-bottom: 4px;
  color: #fff;
}

.traildetailbadgeboxtext p {
  margin: 0px auto;
  font-size: 14px;
  line-height: 140%;
  opacity: 0.8;
}

.page-trailer {
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.page-trailer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pagewrapper-trailer {
  opacity: 1;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);
  visibility: visible;
}

.pagewrapper-trailer-closed {
  opacity: 0;
  visibility: hidden;
}

.imagecredits {
  background-color: #0008;
  color: #fffe;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  padding: 6px 10px;
  line-height: 1;
  border-top-right-radius: 8px;
  backdrop-filter: blur(6px);
  z-index: 1;
}

.traildetailbadgeboxbutton {
    color: #fff;
    border: 2px solid #fff4;
    display: inline-flex;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 12px;
    cursor: pointer;
    align-items: center;
    margin-top: 12px;
}